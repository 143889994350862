import { Button, Radio, DatePicker, Row, Col, Select } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styles from "./Filters.module.css";
import { ColumnsGroup } from "./ColumnsGroup";
import {
  generateChannels,
  generateStatuses,
  generateCountries,
} from "../utils/filterOptions";
import moment from "moment";
import { columnPreset } from "../utils/columnsList";
import { ColumnInfo } from "../../../components/ColumnInfo";
import { agencyService } from "../../../services/agencyService";

const { RangePicker } = DatePicker;

export const Filters = ({
  onSubmit,
  allColumns,
  dateRange,
  setDateRange,
  handleExport,
}) => {
  const [checkedColumns, setCheckedColumns] = useState(columnPreset);
  const [filters, setFilters] = useState({});
  const [channels, setChannels] = useState([]);
  const [countries, setCountries] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const [columnInfoVisible, setColumnInfoVisible] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await agencyService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getChannels = async () => {
      setAutocompleteLoading(true);
      try {
        const res = await agencyService.getChannels("daily");
        if (res) {
          setChannels(res);
        }
      } catch (e) {
        console.log(e);
        // TODO: handle error?
      } finally {
        setAutocompleteLoading(false);
      }
    };
    getChannels();
    getCountries();
  }, []);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      // filters: urlFilters || filters,
      filters,
      dateRange,
      checkedColumns: urlCheckedColumns || checkedColumns,
    });
  };

  useEffect(() => {
    handleUpdate();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="last_report"
          size="large"
        >
          <Radio.Button
            value="last_report"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "days"),
                to: moment().subtract(1, "days"),
              })
            }
          >
            Last Report
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({ from: moment().subtract(6, "days"), to: moment() })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div className={styles.advancedFilters}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col md={24} lg={6} xxl={8}>
              <span className={styles.spanMarginBottom}>Date range</span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={false}
                // className={styles.rangeDatePicker}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
          </Row>
          <div style={{ marginTop: "1%" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Status</span>
                <Select
                  mode="multiple"
                  onChange={(value) =>
                    setFilters({ ...filters, status: value })
                  }
                  style={{ width: "100%" }}
                  placeholder="Select status"
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  value={filters.status}
                  allowClear
                >
                  {generateStatuses()}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Country</span>
                <Select
                  mode="multiple"
                  onChange={(value) =>
                    setFilters({ ...filters, country: value })
                  }
                  style={{ width: "100%" }}
                  placeholder="Select country"
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  value={filters.country}
                  allowClear
                >
                  {generateCountries(countries || [])}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Search Channel</span>
                <Select
                  mode="multiple"
                  onChange={(value) =>
                    setFilters({ ...filters, search_channel: value })
                  }
                  loading={autocompleteLoading}
                  // filterOption={false}
                  style={{ width: "100%" }}
                  placeholder="Select channels"
                  value={filters.search_channel}
                  allowClear
                >
                  {generateChannels(channels)}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xxl={4}>
                <Button
                  style={{ marginTop: "22px", height: "32px", width: "100%" }}
                  className={styles.applyFiltersBtn}
                  type="primary"
                  size="medium"
                  onClick={handleUpdate}
                >
                  Apply Filters
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={styles.bottomActions}>
        <div className={styles.rightActionBtns}>
          <div>
            <ColumnsGroup
              columnsData={allColumns}
              onChange={(value) => {
                setCheckedColumns(value);
              }}
              showUpdateButton={true}
              onUpdate={handleUpdate}
              checkedColumns={checkedColumns}
            />
            <Button
              className={styles.columnsBtn}
              onClick={() => setColumnInfoVisible(true)}
            >
              Column Info
            </Button>
          </div>
          <div className={styles.lastImportExport}>
            <div>
              <Button
                onClick={handleExport}
                className={styles.columnsBtn}
                icon={<ExportOutlined />}
              >
                Export to CSV
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ColumnInfo
        visible={columnInfoVisible}
        setVisible={setColumnInfoVisible}
        allColumns={allColumns}
      />
    </>
  );
};
